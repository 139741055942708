// import SlimSelect from "slim-select";
import gsap from "gsap";
import qs from "qs";
import httpA from "coreJs/actions/httpActions";

const { Loader } = require("google-maps");
// import { Loader, LoaderOptions } from 'google-maps';
// or const {Loader} = require('google-maps'); without typescript

class Map {
    constructor() {
        if (typeof BLOCK_MAP !== "undefined") {
            this.iwindows = [];
            this.mapData = BLOCK_MAP; // eslint-disable-line
            this.map = null;
            this.slim = null;
            this.lat = 0;
            this.lng = 0;
            this.defaultLat = 0;
            this.defaultLng = 0;
            this.zoom = 0;
            this.default = {};
            this.action = {
                storeLocator: "store_locator",
                findCity: "find_city",
            };
            this.loaderUrl = `${GLOBALS.TEMPLATE}/dist/img/action_loader.svg`; // eslint-disable-line
            this.markers = [];
            this.svg = this.mapData.img;
            this.debounceTime = 500;
            this.icon = {
                path: "M44.33,7.69a25.76,25.76,0,0,0-36.72,0,26.45,26.45,0,0,0,0,37.13L24.59,62a1.92,1.92,0,0,0,2.75,0l17-17.18a26.47,26.47,0,0,0,0-37.13ZM26.26,33.43A7.86,7.86,0,1,1,34,25.57,7.83,7.83,0,0,1,26.26,33.43Z",
                fillColor: "#3C4247",
                fillOpacity: 1,
                strokeWeight: 0,
                strokeColor: "",
                scale: 0.6,
            };
            this.children = "";
            this.listContainer = document.querySelector(".store__locator_list");

            this.elements = {
                triggerFindClosestStore: ".storelocatore__locate-store",
                defaultStores: ".storelocator__single",
                form: "form.storelocator__form",
                searchField: ".storelocator__search-field",
                datalist: "#storelocator__list",
                reset: ".storelocator__reset",
                resetContainer: ".storelocator__reset_container",
                loader: {
                    map: ".loaderaction__map",
                },
            };
            this.loader = {};
            this.loader.map = {
                class: ".loaderaction__map",
                toggle: "loaderaction__map_hidden",
            };
            this.loader.input = {
                class: ".loaderaction__input",
                toggle: "loaderaction__input_hidden",
            };

            if (document.querySelector(".map")) {
                this.init();
            }
        }
    }

    init() {
        this.buildMap();
        this.initEvent();
    }

    async buildMap() {
        // default value, center on France
        const defaultStores = Array.from(document.querySelectorAll(this.elements.defaultStores));
        if (defaultStores.length === 1) {
            this.lat = +defaultStores[0].getAttribute("data-lat");
            this.lng = +defaultStores[0].getAttribute("data-lng");
            this.zoom = 12;
        } else {
            this.lat = this.mapData.coords.lat;
            this.lng = this.mapData.coords.lng;
            this.zoom = this.mapData.coords.zoom;
        }

        this.defaultLat = this.mapData.coords.lat;
        this.defaultLng = this.mapData.coords.lng;
        const defaultNode = document.querySelector(".store__locator_list");
        if (defaultNode) this.default.list = defaultNode.innerHTML;
        return new Promise((resolve) => {
            const loader = new Loader(this.mapData.map_api, {});
            loader.load().then((google) => {
                this.google = google;
                // this.icon.anchor = new this.google.maps.Point(0, 0);
                this.map = new google.maps.Map(document.querySelector(".map__inner"), {
                    center: { lat: this.lat, lng: this.lng },
                    zoom: this.zoom,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    rotateControl: false,
                });
                this.map.setOptions({
                    styles: [
                        {
                            elementType: "geometry",
                            stylers: [
                                {
                                    color: "#f5f5f5",
                                },
                            ],
                        },
                        {
                            elementType: "labels.icon",
                            stylers: [
                                {
                                    visibility: "off",
                                },
                            ],
                        },
                        {
                            elementType: "labels.text.fill",
                            stylers: [
                                {
                                    color: "#616161",
                                },
                            ],
                        },
                        {
                            elementType: "labels.text.stroke",
                            stylers: [
                                {
                                    color: "#f5f5f5",
                                },
                            ],
                        },
                        {
                            featureType: "administrative.land_parcel",
                            elementType: "labels.text.fill",
                            stylers: [
                                {
                                    color: "#bdbdbd",
                                },
                            ],
                        },
                        {
                            featureType: "administrative.locality",
                            stylers: [
                                {
                                    visibility: "on",
                                },
                            ],
                        },
                        {
                            featureType: "administrative.locality",
                            elementType: "labels.text",
                            stylers: [
                                {
                                    visibility: "on",
                                },
                            ],
                        },
                        {
                            featureType: "poi",
                            elementType: "geometry",
                            stylers: [
                                {
                                    color: "#eeeeee",
                                },
                            ],
                        },
                        {
                            featureType: "poi",
                            elementType: "labels.text.fill",
                            stylers: [
                                {
                                    color: "#757575",
                                },
                            ],
                        },
                        {
                            featureType: "poi.park",
                            elementType: "geometry",
                            stylers: [
                                {
                                    color: "#e5e5e5",
                                },
                            ],
                        },
                        {
                            featureType: "poi.park",
                            elementType: "labels.text.fill",
                            stylers: [
                                {
                                    color: "#9e9e9e",
                                },
                            ],
                        },
                        {
                            featureType: "road",
                            elementType: "geometry",
                            stylers: [
                                {
                                    color: "#ffffff",
                                },
                            ],
                        },
                        {
                            featureType: "road.arterial",
                            elementType: "labels.text.fill",
                            stylers: [
                                {
                                    color: "#757575",
                                },
                            ],
                        },
                        {
                            featureType: "road.highway",
                            elementType: "geometry",
                            stylers: [
                                {
                                    color: "#dadada",
                                },
                            ],
                        },
                        {
                            featureType: "road.highway",
                            elementType: "labels.text.fill",
                            stylers: [
                                {
                                    color: "#616161",
                                },
                            ],
                        },
                        {
                            featureType: "road.local",
                            elementType: "labels.text.fill",
                            stylers: [
                                {
                                    color: "#9e9e9e",
                                },
                            ],
                        },
                        {
                            featureType: "transit.line",
                            elementType: "geometry",
                            stylers: [
                                {
                                    color: "#e5e5e5",
                                },
                            ],
                        },
                        {
                            featureType: "transit.station",
                            elementType: "geometry",
                            stylers: [
                                {
                                    color: "#eeeeee",
                                },
                            ],
                        },
                        {
                            featureType: "water",
                            elementType: "geometry",
                            stylers: [
                                {
                                    color: "#c9c9c9",
                                },
                            ],
                        },
                        {
                            featureType: "water",
                            elementType: "labels.text.fill",
                            stylers: [
                                {
                                    color: "#9e9e9e",
                                },
                            ],
                        },
                    ],
                });
                this.icon.anchor = new this.google.maps.Point(27, 65);
                this.toggleLoader("map");
                defaultStores.forEach((elem) => {
                    this.buildMarker(
                        elem.getAttribute("data-lat"),
                        elem.getAttribute("data-lng"),
                        elem.getAttribute("data-store")
                    );
                    elem.addEventListener("mouseenter", () => {
                        this.toggleHighlight(elem);
                    });
                    elem.addEventListener("mouseleave", () => {
                        this.toggleHighlight(elem);
                    });
                });
                this.default.markers = this.markers;
                resolve();
            });
        });
    }

    initEvent() {
        if (document.querySelector(this.elements.triggerFindClosestStore)) {
            document.querySelector(this.elements.triggerFindClosestStore).addEventListener("click", () => {
                this.toggleLoader("map");
                this.findClosestStore();
            });
        }

        const searchField = document.querySelector(this.elements.searchField);
        if (searchField) {
            searchField.addEventListener("input", function () {
                this.value = this.value.replace(/[^\dabAB]/g, "");
            });

            // const that = this;
            // this.slim = new SlimSelect({
            //     select: this.elements.searchField,
            //     placeholder: "Cherchez un code postal",
            //     searchPlaceholder: "Cherchez un code postal",
            //     searchText: "Aucun résultat",
            //     searchingText: "Recherche en cours...", // Optional - Will show during ajax request
            //     afterOpen: function () {
            //         const container = document.querySelector("." + this.config.id);
            //         if (!container || container.classList.contains("listened")) return;

            //         const input = container.querySelector(".ss-search input");
            //         input.addEventListener("input", () => {
            //             input.value = input.value.replace(/[^\dabAB]/g, "");
            //         });

            //         container.classList.add("listened");
            //     },
            //     onChange: (info) => {
            //         that.toggleLoader("map");
            //         const datas = {
            //             lat: info.data.dataLat,
            //             lng: info.data.dataLng,
            //             action: that.action.storeLocator,
            //         };

            //         this.findStore(datas);
            //     },
            //     // onInput
            //     ajax: (search, callback) => {
            //         // Check search value. If you dont like it callback(false) or callback('Message String')
            //         if (search.length < 5) {
            //             callback("Veuillez rentrer au moins 5 caractères");
            //             return;
            //         }
            //         const datas = {
            //             action: that.action.findCity,
            //             queryText: search,
            //         };
            //         httpA
            //             .post("", GLOBALS.FORM_ACTION, qs.stringify(datas), { responseType: "text" }) // eslint-disable-line
            //             .then((response) => {
            //                 const cities = response.data;
            //                 const data = [];
            //                 for (let i = 0; i < cities.length; i++) {
            //                     data.push({
            //                         text: cities[i].text,
            //                         value: cities[i].text,
            //                         data: {
            //                             "data-lat": cities[i].coords[0],
            //                             "data-lng": cities[i].coords[1],
            //                         },
            //                     });
            //                 }

            //                 // Upon successful fetch send data to callback function.
            //                 // Be sure to send data back in the proper format.
            //                 // Refer to the method setData for examples of proper format.
            //                 callback(data);
            //             })
            //             .catch((e) => {
            //                 callback(false);
            //             });
            //     },
            // });
        }

        if (document.querySelector(this.elements.form)) {
            document.querySelector(this.elements.form).addEventListener("submit", async (e) => {
                e.preventDefault();
                this.toggleLoader("input");

                if (searchField.value === "") {
                    this.toggleLoader("input");
                    return;
                }

                const zip = searchField.value + "0".repeat(5 - searchField.value.length);
                const coords = await this.geolocateZipcode(zip);

                if (coords) {
                    this.toggleLoader("map");
                    document.querySelector(".storelocatore__error").innerHTML = "";

                    this.lat = coords.lat;
                    this.lng = coords.lng;

                    const datas = {
                        lat: this.lat,
                        lng: this.lng,
                        action: this.action.storeLocator,
                    };

                    this.findStore(datas);
                } else {
                    document.querySelector(".storelocatore__error").innerHTML =
                        "Aucune ville trouvée pour ce code postal.";
                }

                this.toggleLoader("input");
            });
        }

        if (document.querySelector(this.elements.reset)) {
            document.querySelector(this.elements.reset).addEventListener("click", () => {
                this.markerSetMap(this.markers, null);
                document.querySelector(".store__locator_list").innerHTML = this.default.list;
                this.markerSetMap(this.default.markers, this.map);
                this.map.setCenter({ lat: this.defaultLat, lng: this.defaultLng });
                this.map.setZoom(this.zoom);
                Array.from(document.querySelectorAll(this.elements.defaultStores)).forEach((elem) => {
                    elem.addEventListener("mouseenter", () => {
                        this.toggleHighlight(elem);
                    });
                    elem.addEventListener("mouseleave", () => {
                        this.toggleHighlight(elem);
                    });
                });
                document.querySelector(".storelocator__found_number").innerHTML = Object.keys(
                    this.default.markers
                ).length;
                document.querySelector(this.elements.searchField).value = "";
                document.querySelector(this.elements.resetContainer).classList.add("hidden");
            });
        }
    }

    async geolocateZipcode(zip) {
        const datas = {
            action: this.action.findCity,
            queryText: zip,
        };

        return httpA
            .post("", GLOBALS.FORM_ACTION, qs.stringify(datas), { responseType: "text" }) // eslint-disable-line
            .then((response) => {
                const cities = response.data;
                if (!cities.length) return false;

                return {
                    lat: cities[0].coords[0],
                    lng: cities[0].coords[1],
                };
            })
            .catch((e) => {
                return false;
            });
    }

    findClosestStore() {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                // if geoloc get center
                this.lat = position.coords.latitude;
                this.lng = position.coords.longitude;
                // this.zoom = 11;
                const datas = {
                    lat: this.lat,
                    lng: this.lng,
                    action: this.action.storeLocator,
                };
                this.findStore(datas);
            },
            () => {
                this.toggleLoader("map");
            }
        );
    }

    findStore(datas) {
        httpA
            .post("", GLOBALS.FORM_ACTION, qs.stringify(datas), { responseType: "text" }) // eslint-disable-line
            .then((response) => {
                const bounds = new this.google.maps.LatLngBounds();
                bounds.extend({
                    lat: parseFloat(datas.lat),
                    lng: parseFloat(datas.lng),
                });

                this.children = "";
                this.markerSetMap(this.markers, null);

                Array.from(response.data).forEach((store) => {
                    bounds.extend({
                        lat: parseFloat(store.data.coords.lat),
                        lng: parseFloat(store.data.coords.lng),
                    });

                    this.buildMarker(store.data.coords.lat, store.data.coords.lng, store.data.id);
                    this.createListElement(store.data);
                });

                this.listContainer.innerHTML = this.children;
                document.querySelector(".storelocator__found_number").innerHTML = Object.keys(
                    response.data
                ).length;
                Array.from(document.querySelectorAll(this.elements.defaultStores)).forEach((elem) => {
                    elem.addEventListener("mouseenter", () => {
                        this.toggleHighlight(elem);
                    });
                    elem.addEventListener("mouseleave", () => {
                        this.toggleHighlight(elem);
                    });
                });
                // Case: Single marker.
                if (this.markers.length === 1) {
                    this.map.setCenter(bounds.getCenter());
                    this.map.setZoom(15);
                    // Case: Multiple markers.
                } else {
                    this.map.fitBounds(bounds);
                }
                document.querySelector(this.elements.resetContainer).classList.remove("hidden");
                this.toggleLoader("map");
            })
            .catch((e) => {
                console.error(e); // eslint-disable-line
            });
    }

    buildMarker(lat, lng, id) {
        const latlng = new this.google.maps.LatLng(lat, lng);
        const marker = new this.google.maps.Marker({
            position: latlng,
            map: this.map,
            icon: this.icon,
        });

        google.maps.event.addListener(marker, "click", () => {
            const correspondingDiv = document.querySelector(`[data-store="${id}"]`);
            this.toggleHighlight(correspondingDiv);
            correspondingDiv.scrollIntoView({ block: "start", behavior: "smooth" });
            // const parentEl = document.querySelector('.store__locator_list');
            // const topPos = correspondingDiv.offsetTop;
            // parentEl.scrollTop = topPos - 300;
        });

        const tmp = { [id]: marker };
        this.markers = { ...this.markers, ...tmp };
    }

    findCities(value) {
        const datas = {
            action: this.action.findCity,
            queryText: value,
        };
        httpA
            .post("", GLOBALS.FORM_ACTION, qs.stringify(datas), { responseType: "text" }) // eslint-disable-line
            .then((response) => {
                const cities = response.data;
                this.populateDatalist(cities);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    populateDatalist(cities) {
        const datalist = document.querySelector(this.elements.datalist);
        datalist.innerHTML = "";
        Object.keys(cities).forEach((key) => {
            const option = document.createElement("option");
            option.value = cities[key].text;
            option.setAttribute("data-lat", cities[key].coords[0]);
            option.setAttribute("data-lng", cities[key].coords[1]);
            datalist.appendChild(option);
        });
        this.toggleLoader("input");
    }

    debounce(callback, wait) {
        let timeout;
        return () => {
            const functionCall = () => callback.apply(this, arguments); // eslint-disable-line

            clearTimeout(timeout);
            timeout = setTimeout(functionCall, wait);
        };
    }

    toggleLoader(identifier) {
        const elem = document.querySelector(this.loader[identifier].class);
        const tl = gsap.timeline();
        if (elem.classList.contains(this.loader[identifier].toggle)) {
            tl.set(elem, { display: "flex" }).to(elem, { opacity: 1 });
        } else {
            tl.to(elem, { opacity: 0 }).set(elem, { display: "none" });
        }
        elem.classList.toggle(this.loader[identifier].toggle);
    }

    toggleHighlight(elem) {
        if (document.querySelector(".storelocator__single_active")) {
            const active = document.querySelector(".storelocator__single_active");
            this.icon.fillColor = "#3C4247";
            this.markers[active.getAttribute("data-store")].setIcon(this.icon);
            active.classList.remove("storelocator__single_active");
        }
        this.icon.fillColor = "#fa0000";
        elem.classList.add("storelocator__single_active");
        this.markers[elem.getAttribute("data-store")].setIcon(this.icon);
    }

    markerSetMap(markers, map) {
        const keys = Object.keys(markers);
        const nb = keys.length;
        for (let i = 0; i < nb; i++) {
            markers[keys[i]].setMap(map);
        }
    }

    createListElement(elem) {
        let listElement = `
            <a href="${elem.url}" class="storelocator__single" data-store="${elem.id}" data-lat="${elem.store_address.lat}" data-lng="${elem.store_address.lng}">
            `;

        listElement += `
            <div class="storelocator__icon">
                <svg class="icon icon--grey icon--large">
                    <use xlink:href="#icon-pin-full"></use>
                </svg>
            </div>
            `;

        listElement += `
                <div class="storelocatore__content">
                    <div class="storelocator__name">${elem.post_title.replace("Votre magasin ", "")}</div>
                    <div class="storelocator__address">${elem.store_formatted_address.replace(
                        ", France",
                        ""
                    )}<br>${elem.store_phone}</div>
                    <span class="storelocator__status${elem.open.status ? " storelocator__status_open" : ""}">
                        ${elem.open.status ? "Ouvert jusqu'à " + elem.open.closing : ""}
                    </span>
                </div>
            </a>
        `;

        this.children += listElement;
    }
}

export default Map;
