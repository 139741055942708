// import AOS from 'aos';
// import gsap from 'gsap';
import SmoothScroll from "smoothscroll-for-websites";
import smoothscroll from "smoothscroll-polyfill";
// import isMobile from './isMobile';
// import BodyMovin from '../../modules/bodyMovin';
import Map from "./map";
import Site from "./site";
import Geoloc from "./geoloc";
import Wishlist from "./wishlist";
import Product from "./product";
import App from "./app";
import Viewer from "./viewer3D";
import Home from "./home";
import Lightbox from "./lightbox";
import Splider from "./crossSelling";
import { ProductCategories } from "./productCategories";

window.onload = () => {
    smoothscroll.polyfill();
    SmoothScroll({
        stepSize: 80,
        accelerationMax: 10,
        animationTime: 1000,
    });

    setTimeout(() => {
        // AOS.init({
        //     once: true,
        // });
        document.querySelector("body").classList.remove();
        document.querySelector("body").classList.add("loading-complete");
        var app = new Site();

        $(function () {
            app.detectIfMobile();
        });
        const appsite = new App();
        const map = new Map(); // eslint-disable-line
        const g = new Geoloc(); // eslint-disable-line
        const wishlist = new Wishlist(); // eslint-disable-line
        const product = new Product();
        const lightbox = new Lightbox(); // eslint-disable-line
        const home = new Home();
        const view = new Viewer();
        const crossSelling = new Splider(); // eslint-disable-line
        const productCategories = new ProductCategories(); // eslint-disable-line
        view.init();

        window.wishlist = wishlist;
        window.wishlist.reload = () => {
            wishlist.initEvents();
        };
    }, 201);
};
