class Blocks {
    constructor() {}

    init() {
        const myClass = this;

        if ($(".block-slider--block").length) {
            $(".block-slider--block").each(function (index) {
                myClass.initBlockSliderHome($(this));
            });
        }
        if ($(".block-slider--product").length) {
            $(".block-slider--product").each(function (index) {
                myClass.initBlockSliderProduct($(this));
            });
        }
        if ($(".block-accordeon").length) {
            $(".block-accordeon").each(function (index) {
                myClass.initBlockAccordeon($(this));
            });
        }

        if ($(".block-accordeon3D").length) {
            $(".block-accordeon3D").each(function (index) {
                myClass.initBlockAccordeon3D($(this));
            });
        }
        if ($(".slider-services").length) {
            $(".slider-services").each(function (index) {
                myClass.initBlockSliderServices($(this));
            });
        }
        if ($(".slider-testimonies").length) {
            $(".slider-testimonies").each(function (index) {
                myClass.initBlockSliderTestimonies($(this));
            });
        }
        if ($(".slider-stepper").length) {
            $(".slider-stepper").each(function (index) {
                myClass.initBlockSliderStepper($(this));
            });
        }
        if ($(".block-seo").length) {
            $(".block-seo").each(function (index) {
                myClass.initBlockSeo($(this));
            });
        }
        if ($(".block-inspiration").length) {
            $(".block-inspiration").each(function (index) {
                myClass.initBlockInspiration($(this));
            });
        }
        // if ($('.card-product').length) {
        //     $('.card-product').each(function( index ) { myClass.initSliderColor($(this));  });
        // }
        if ($(".box-reveal").length) {
            $(".box-reveal").each(function (index) {
                myClass.initBoxReveal($(this));
            });
        }
        if ($(".sticky-store").length) {
            $(".sticky-store").each(function (index) {
                myClass.initStickyStoreMobile($(this));
            });
        }
        if ($(".filters").length) {
            $(".filters").each(function (index) {
                myClass.initFilters($(this));
            });
        }
        if ($(".sort-products").length) {
            $(".sort-products").each(function (index) {
                myClass.initSort($(this));
            });
        }
        if ($(".filters-mobile").length) {
            $(".filters-mobile").each(function (index) {
                myClass.initFiltersMobile($(this));
                myClass.initAccordion($(this)); // accordion for panel filters
                myClass.initStickyFiltersMobile($(this));
            });
        }
        if ($(".dropdown__btn").length) {
            $(".dropdown__btn").each(function (index) {
                myClass.initDropdown(this);
            });
        }
        if ($(".godown_btn").length) {
            $(".godown_btn").on("click", function () {
                const target = $(this).attr("data-target");
                $("html, body").animate({ scrollTop: $(`#${target}`).offset().top - 100 }, 333);
            });
        }

        $(".footer__col-title").on("click", function () {
            $(this).toggleClass("is-open");
        });
    }

    //        Dropdown generic system
    // ==============================================

    initDropdown(el) {
        // open / close dropdown
        $(el).on("click", function (e) {
            e.preventDefault();
            // active menu
            const currentEl = $(this);
            if (!$(currentEl).hasClass("is-open")) {
                $(currentEl).addClass("is-open");
                // detect click outside
                $(document).on("click", (e) => {
                    if (!$(e.target).closest(currentEl).length) {
                        $(currentEl).removeClass("is-open");
                        $(document).off("click");
                    }
                });
            } else {
                $(currentEl).removeClass("is-open");
                $(document).off("click");
                e.stopPropagation();
            }
        });
    }

    //        Accordion generic system
    // ==============================================

    initAccordion($block) {
        const btnAccordion = $block.find(".accordion__btn");

        // open / close accordion
        $(btnAccordion).on("click", function (e) {
            e.preventDefault();

            // parent menu (<li>)
            const itemAccordion = $(this).parent();

            if ($(itemAccordion).hasClass("is-open")) {
                $(itemAccordion).removeClass("is-open");
            } else {
                $(btnAccordion).parent().removeClass("is-open");
                $(itemAccordion).addClass("is-open");
            }
        });
    }

    //        Filter & Sort on mobile
    // ==============================================

    initFiltersMobile($block) {
        // let btnOpenSort   = $block.find('.filters-mobile__btn-sort');
        const btnOpenFilter = $block.find(".filters-mobile__btn-filter");
        // let panelSort     = $block.find('.panel-sort');
        const panelFilter = $(".filters");

        // open / close panel sort
        // $(btnOpenSort).on('click', function(e){
        //     $(panelSort).addClass('is-open');
        //     $('body').addClass('no-scroll');
        // });
        // $(panelSort).find($('.btn-close-panel')).on('click', function(e){
        //     $(panelSort).removeClass('is-open');
        //     $('body').removeClass('no-scroll');
        // });

        // open / close panel filter
        $(btnOpenFilter).on("click", (e) => {
            $(panelFilter).addClass("open");
            $("body").addClass("no-scroll");
        });
        $(panelFilter)
            .find($(".btn-close-panel"))
            .on("click", (e) => {
                $(panelFilter).removeClass("open");
                $("body").removeClass("no-scroll");
            });
    }

    initBlockSliderStepper($block) {
        const slider = $block;
        const sliderSettings = {
            rows: 0, // fix bug to make slide param works (https://stackoverflow.com/questions/30036742/jquery-slick-carousel-slide-setting)
            arrows: true,
            prevArrow:
                '<button type="button" class="slick-prev slick-btn"><div class="slick-btn__inner secondary"><svg class="icon"><use xlink:href="#icon-chevron"></use></svg ></div></button>',
            nextArrow:
                '<button type="button" class="slick-next slick-btn"><div class="slick-btn__inner secondary"><svg class="icon"><use xlink:href="#icon-chevron"></use></svg ></div></button>',
            dots: true,
            infinite: false,
            slidesToShow: 3.2,
            draggable: true,
            centerMode: true,
            responsive: [
                { breakpoint: 1200, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                { breakpoint: 768, settings: { slidesToShow: 1, slidesToScroll: 1 } },
            ],
        };

        $(slider).slick(sliderSettings);
    }

    //        Sort products
    // ==============================================

    initSort($block) {
        const myClass = this;
        const btnMenu = $block.find(".sort-products-box__button");
        const btnSort = $(".sort-menu__link");

        // open / close menu sort
        $(btnMenu).on("click", function (e) {
            e.preventDefault();

            // active menu
            const currentMenu = $(this).parent();

            if ($(currentMenu).hasClass("is-open")) {
                myClass.closeMenuSort(currentMenu);
            } else {
                myClass.openMenuSort(currentMenu);
                e.stopPropagation();
            }
        });

        // activate / deactivate sort
        $(btnSort).on("click", function (e) {
            e.preventDefault();

            // active sort
            const currentBtnSort = $(this).parent();

            if (!$(currentBtnSort).hasClass("is-active")) {
                $(btnSort).parent().removeClass("is-active");
                $(currentBtnSort).addClass("is-active");
            }
        });
    }

    // open / close menu sort
    openMenuSort(currentMenu) {
        const myClass = this;

        $(currentMenu).addClass("is-open");

        // detect click outside
        $(document).on("click", (e) => {
            if (!$(e.target).closest(currentMenu).length) {
                myClass.closeMenuFilters(currentMenu);
            }
        });
    }

    closeMenuSort(currentMenu) {
        $(currentMenu).removeClass("is-open");
        $(document).off("click");
    }

    //        Filters products
    // ==============================================

    initFilters($block) {
        const myClass = this;
        const btnMenu = $block.find(".filters-menu__link");
        const btnFilter = $(".filters-submenu__link"); // find in block and in mobile panel
        // let btnFilter = $block.find('.filters-submenu__link');
        const btnTag = $block.find(".filters-tags__link");
        const btnReset = $block.find(".btn-reset-filters");

        // open / close menu filter
        $(btnMenu).on("click", function (e) {
            e.preventDefault();

            // active menu (<li>)
            const currentMenu = $(this).parents(".filters-menu__item");
            // let currentMenu = $(this).parent().parent();

            if ($(currentMenu).hasClass("is-open")) {
                myClass.closeMenuFilters(currentMenu);
            } else {
                myClass.openMenuFilters(currentMenu, btnMenu);
                e.stopPropagation();
            }
        });

        // activate / deactivate filter
        // $(btnFilter).on('click', function(e){
        //     e.preventDefault();

        //     // active filter
        //     let currentFilter = $(this).parent();
        //     let id            = $(currentFilter).data('id');

        //     // if ($(currentFilter).hasClass('is-active')) {
        //     //     myClass.deactivateFilter($block, id);
        //     // } else {
        //     //     myClass.activateFilter($block, id);
        //     // }
        // });

        // remove tag filter if exist
        $(btnTag).on("click", function (e) {
            e.preventDefault();

            const id = $(this).parent().data("id");
            myClass.deactivateFilter($block, id);
        });

        // remove all tag filters
        $(btnReset).on("click", function (e) {
            $(this).hide();
            myClass.resetFilters($block);
        });
    }

    // activate / deactivate filter by id
    activateFilter($block, id) {
        const myClass = this;
        // let currentFilter = $block.find(`.filters-submenu__item[data-id="${id}"]`);
        const currentFilter = $(`.filters-submenu__item[data-id="${id}"]`);
        const label = $(currentFilter).first().find(".filters-submenu__link span").text();
        $(currentFilter).addClass("is-active");

        // create tag filter
        const tagFilter = `<li class="filters-tags__item" data-id="${id}">
                                <a href="#" class="filters-tags__link">
                                    <svg class="icon"><use xlink:href="#icon-close"></use></svg>
                                    <span>${label}</span>
                                </a>
                            </li>`;

        const newTagFilter = $(tagFilter).insertBefore($block.find(".btn-reset-filters"));
        // let newTagFilter = $(tagFilter).appendTo($block.find('.filters-tags__list'));

        // remove tag filter
        $(newTagFilter)
            .find(".filters-tags__link")
            .on("click", function (e) {
                e.preventDefault();

                const id = $(this).parent().data("id");
                myClass.deactivateFilter($block, id);
            });

        // show btn reset
        $block.find(".btn-reset-filters").show();
    }

    deactivateFilter($block, id) {
        // let currentFilter = $block.find(`.filters-submenu__item[data-id="${id}"]`);
        const currentFilter = $(`.filters-submenu__item[data-id="${id}"]`);
        $(currentFilter).removeClass("is-active");

        // remove tag filter
        const tagFilter = $block.find(`.filters-tags__item[data-id="${id}"]`);
        $(tagFilter).remove();

        // no filter left - hide btn reset
        if ($block.find(".filters-tags__list").children().length == 1) {
            $block.find(".btn-reset-filters").hide();
        }
    }

    // remove all tag filters
    resetFilters($block) {
        $block.find(".filters-submenu__item").removeClass("is-active");

        // remove tag filter
        const tagFilter = $block.find(".filters-tags__item");
        $(tagFilter).remove();
    }

    // open / close menu filters
    openMenuFilters(currentMenu, btnMenu) {
        const myClass = this;

        $(btnMenu).parent(".filters-menu__item").removeClass("is-open");
        $(currentMenu).addClass("is-open");

        // detect click outside
        $(document).on("click", (e) => {
            if (!$(e.target).closest(currentMenu).length) {
                myClass.closeMenuFilters(currentMenu);
                // console.log('outside');
            }
        });
    }

    closeMenuFilters(currentMenu) {
        $(currentMenu).removeClass("is-open");
        $(document).off("click");
    }

    //        Stick filters / sort bar on list products page
    // ==============================================

    initStickyFiltersMobile($block) {
        $block.find(".filters-mobile-bar").stick_in_parent({ parent: ".list-products" });
    }

    //        Stick store infos on store page (on mobile)
    // ==============================================

    initStickyStoreMobile($block) {
        $block.stick_in_parent();
    }

    //        Box reveal (store page, product page)
    // ==============================================

    initBoxReveal($block) {
        const btnReveal = $block.find(".btn-reveal");
        const initialHeight = window.getComputedStyle($block[0]).getPropertyValue("--initial-height");

        // console.log('offsetHeight', $block[0].offsetHeight);
        // console.log('scrollHeight', $block[0].scrollHeight);
        // console.log('initialHeight', initialHeight);

        // open / close
        $(btnReveal).on("click", (e) => {
            e.preventDefault();
            let newHeight;

            if ($block.hasClass("is-open")) {
                newHeight = initialHeight;
                $block.removeClass("is-open");
                $(btnReveal).find("span").html("En savoir plus");
                $(btnReveal).removeClass("is-open");
            } else {
                newHeight = `${$block[0].scrollHeight}px`;
                $block.addClass("is-open");
                $(btnReveal).find("span").html("Fermer");
                $(btnReveal).addClass("is-open");
            }
            $block[0].style.setProperty("--current-height", newHeight);
        });
    }

    //        Slider colors in card-product
    // ==============================================

    initSliderColor($block) {
        const slider = $block.find(".slider-colors");
        const slide = $block.find(".slide-color");

        const sliderSettings = {
            slide: ".slide-color",
            rows: 0, // fix bug to make slide param works (https://stackoverflow.com/questions/30036742/jquery-slick-carousel-slide-setting)
            arrows: true,
            prevArrow: $block.find(".slick-prev"),
            nextArrow: $block.find(".slick-next"),
            speed: 300,
            cssEase: "ease",
            infinite: false,
            draggable: false,
            slidesToShow: 6,
            slidesToScroll: 6,

            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                    },
                },
            ],
        };

        if ($(slide).length > 1) {
            $(slider).slick(sliderSettings);
        } else {
            $block.find(".slick-prev").css("display", "none");
            $block.find(".slick-next").css("display", "none");
        }
    }

    //        Block inspiration
    // ==============================================

    initBlockInspiration($block) {
        const myClass = this;
        const btnInspiration = $block.find(".nav-inspiration__link");

        $(btnInspiration).on("click", (e) => {
            e.preventDefault();

            // active nav
            const currentBtn = e.target;
            const id = $(currentBtn).data("id");
            $(btnInspiration).removeClass("is-active");
            $(currentBtn).addClass("is-active");

            // active grid
            const currentGrid = $block.find(`.grid-inspiration[data-id=${id}]`);
            $block.find(".grid-inspiration").removeClass("is-active");
            $block
                .find(".card-inspiration")
                .removeClass("reveal-init reveal-animation-up reveal-animation-down");
            $(currentGrid).addClass("is-active");

            // animate cards with delay
            const items = $(currentGrid).find(".card-inspiration");
            for (let i = 0; i < items.length; i++) {
                // get function in closure, so i can iterate
                $(items[i]).addClass("reveal-init");
                const addRevealClass = myClass.getAddRevealClass(items[i], i);
                const delay = i * 120;
                setTimeout(addRevealClass, delay);
            }
        });
    }

    getAddRevealClass(el, i) {
        return function () {
            i % 2 ? $(el).addClass("reveal-animation-up") : $(el).addClass("reveal-animation-down");
        };
    }

    //        Block seo
    // ==============================================

    initBlockSeo($block) {
        const btnOpen = $block.find(".block-seo__btn-open");
        const initialHeight = window.getComputedStyle($block[0]).getPropertyValue("--initial-height");

        // console.log('$block', $block[0].offsetHeight);
        // console.log('$block', $block[0].scrollHeight);
        // console.log('$block', initialHeight);

        $(btnOpen).on("click", (e) => {
            e.preventDefault();

            if ($block.hasClass("is-open")) {
                $block.removeClass("is-open");
                $block[0].style.setProperty("--current-height", initialHeight);
                $(btnOpen).html("En savoir plus");
            } else {
                const h = $block[0].scrollHeight;
                $block.addClass("is-open");
                $block[0].style.setProperty("--current-height", `${h}px`);
                $(btnOpen).html("Fermer");
            }
        });
    }

    //        Block slider home
    // ==============================================

    initBlockSliderHome($block) {
        const slider = $block.find(".slider-hero");
        const slide = $block.find(".slide-hero");

        const setting = {
            slide: ".slide-hero",
            rows: 0, // fix bug to make slide param works (https://stackoverflow.com/questions/30036742/jquery-slick-carousel-slide-setting)
            arrows: true,
            prevArrow: $block.find(".slick-prev"),
            nextArrow: $block.find(".slick-next"),
            speed: 500,
            cssEase: "linear",
            infinite: true,
            fade: true,
            draggable: true,
            autoplay: true,
            autoplaySpeed: 5000,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: $(".slider-thumbnails").length ? $(".slider-thumbnails") : "",
        };

        // init -> set active image
        $(slider).on("init", (event, slick) => {
            const currentSlide = 0;
            $(`.slide-hero[data-slick-index=${currentSlide}]`).addClass("is-active");
        });

        if ($(slide).length >= 1) {
            if (!$(slider).hasClass("slick-slider")) {
                $(slider).slick(setting);
            }
        }

        // Zoom efx on current slide
        $(slider).on("beforeChange", (event, slick, currentSlide, nextSlide) => {
            $(`.slide-hero[data-slick-index=${currentSlide}]`).removeClass("is-active");
            $(`.slide-hero[data-slick-index=${nextSlide}]`).addClass("is-active");
        });
        //

        if ($(".slide-thumbnail").length >= 2) {
            const settings = {
                arrows: false,
                dots: false,
                draggable: false,
                focusOnSelect: true,
                slidesToShow: $(".slide-thumbnail").length,
                slidesToScroll: 1,
                asNavFor: ".slider-hero",
            };
            // console.log('settings', settings);
            $(".slider-thumbnails").slick(settings);
        } else $(".slider-thumbnails").hide();
    }

    initBlockSliderProduct($block) {
        const slider = $block.find(".slider-hero");
        const slide = $block.find(".slide-hero");

        const setting = {
            slide: ".slide-hero",
            rows: 0, // fix bug to make slide param works (https://stackoverflow.com/questions/30036742/jquery-slick-carousel-slide-setting)
            arrows: true,
            prevArrow: $block.find(".slick-prev"),
            nextArrow: $block.find(".slick-next"),
            speed: 500,
            cssEase: "linear",
            infinite: true,
            fade: true,
            draggable: false,
            autoplay: false,
            autoplaySpeed: 5000,
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: $(".slider-thumbnails").length ? $(".slider-thumbnails") : "",
        };

        // init -> set active image
        $(slider).on("init", (event, slick) => {
            const currentSlide = 0;
            $(`.slide-hero[data-slick-index=${currentSlide}]`).addClass("is-active");
        });

        if ($(slide).length >= 1) {
            if (!$(slider).hasClass("slick-slider")) {
                $(slider).slick(setting);
            }
        }

        // Zoom efx on current slide
        $(slider).on("beforeChange", (event, slick, currentSlide, nextSlide) => {
            $(`.slide-hero[data-slick-index=${currentSlide}]`).removeClass("is-active");
            $(`.slide-hero[data-slick-index=${nextSlide}]`).addClass("is-active");
        });
        //

        if ($(".slide-thumbnail").length >= 2) {
            const settings = {
                arrows: false,
                dots: false,
                draggable: true,
                focusOnSelect: true,
                slidesToShow: $(".slide-thumbnail").length > 7 ? $(".slide-thumbnail").length : 7,
                centerMode: false,
                asNavFor: ".slider-hero",
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 5,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                        },
                    },
                ],
            };
            // console.log('settings', settings);
            $(".slider-thumbnails").slick(settings);
        } else $(".slider-thumbnails").hide();
    }

    //        Block slider services
    // ==============================================

    initBlockSliderServices($block) {
        const slider = $block;
        const sliderSettings = {
            rows: 0, // fix bug to make slide param works (https://stackoverflow.com/questions/30036742/jquery-slick-carousel-slide-setting)
            arrows: false,
            dots: true,
            infinite: false,
            draggable: true,
            mobileFirst: true,
            responsive: [{ breakpoint: 769, settings: "unslick" }],
        };

        $(slider).slick(sliderSettings);
    }

    //        Block slider testimonies
    // ==============================================

    initBlockSliderTestimonies($block) {
        const slider = $block;
        const sliderSettings = {
            rows: 0, // fix bug to make slide param works (https://stackoverflow.com/questions/30036742/jquery-slick-carousel-slide-setting)
            // arrows      : true,
            prevArrow:
                '<button type="button" class="slick-prev slick-btn"><div class="slick-btn__inner"><svg class="icon"><use xlink:href="#icon-chevron"></use></svg ></div></button>',
            nextArrow:
                '<button type="button" class="slick-next slick-btn"><div class="slick-btn__inner"><svg class="icon"><use xlink:href="#icon-chevron"></use></svg ></div></button>',
            dots: true,
            fade: true,
            infinite: false,
            draggable: true,
        };

        $(slider).slick(sliderSettings);
    }

    //        Block accordeon
    // ==============================================

    initBlockAccordeon(el) {
        $(el)
            .find(".block-accordeon__item")
            .on("click", (event) => {
                event.stopImmediatePropagation();

                if (event.target.nodeName !== "A") {
                    const id = $(event.target).closest(".block-accordeon__item").attr("accordeon-id");
                    const container = $(event.target).closest(".block-accordeon");
                    // console.log('accordeon clicked(', id, ')', container);

                    if (container.find(`.block-accordeon__item[accordeon-id=${id}]`).hasClass("active")) {
                        container.find(`.block-accordeon__item[accordeon-id=${id}]`).removeClass("active");
                    } else {
                        container.find(".block-accordeon__item").removeClass("active"); // remove all before active clicked
                        container.find(`.block-accordeon__item[accordeon-id=${id}]`).addClass("active");
                    }
                    // $('html, body').animate({ scrollTop: container.find('.accordeon__row[accordeon-id=' + id + ']').offset().top - 120 }, 333);
                    event.preventDefault();
                }
            });
        // start with first
        const id = $(el).attr("start-id");
        if (id) {
            $(el).find(`.block-accordeon__item[accordeon-id=${id}]`).addClass("active");
        }
    }

    initBlockAccordeon3D(el) {
        $(el)
            .find(".block-accordeon__item")
            .on("click", (event) => {
                const id = $(event.target).closest(".block-accordeon__item").attr("accordeon-id");
                const container = $(event.target).closest(".block-accordeon3D");
                // console.log('accordeon clicked(', id, ')', container);

                if (!container.find(`.block-accordeon__item[accordeon-id=${id}]`).hasClass("active")) {
                    container.find(".block-accordeon__item").removeClass("active"); // remove all before active clicked
                    container.find(`.block-accordeon__item[accordeon-id=${id}]`).addClass("active");
                }
                // $('html, body').animate({ scrollTop: container.find('.accordeon__row[accordeon-id=' + id + ']').offset().top - 120 }, 333);
                event.preventDefault();
            });
        // start with first
        const id = $(el).attr("start-id");
        if (id) {
            $(el).find(`.block-accordeon__item[accordeon-id=${id}]`).addClass("active");
        }
    }
}

export default Blocks;
