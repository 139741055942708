import { Splide } from "@splidejs/splide";

export class ProductCategories {
    constructor() {
        this.elem = document.querySelector(".productCategories .splide");
        if (!this.elem) return;

        this.init();
    }

    init() {
        const splide = new Splide(this.elem, {
            arrows: false,
            pagination: false,
            perPage: 6,
            autoHeight: true,
            rewind: true,
            gap: "1rem",
            breakpoints: {
                1240: {
                    perPage: 5,
                },
                992: {
                    perPage: 4,
                },
                768: {
                    perPage: 3,
                },
                480: {
                    perPage: 2,
                },
            },
        });

        splide.mount();
    }
}
